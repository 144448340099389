<template>
  <div class="city-list">
    <template v-if="list.length">
      <div class="city-list__side">
        <div class="city-list__side-list">
          <div v-if="regionOpened" class="city-list__back-block">
            <button
              class="city-list__back"
              @click.prevent="$emit('back-click')"
            >
              <svg-icon
                name="16/Arrow_back_16"
                width="10"
                height="8"
                class="city-list__inline-icon"
              />
              Вернуться назад
            </button>
          </div>
          <div v-if="regionOpened" class="city-list__side-item">
            <span
              class="city-list__link"
              :class="{ _static: regionOpened }"
            >
              <b>{{ regionName }}</b>
            </span>
          </div>
          <template v-else>
            <div
              v-for="(city, cityIndex) in popularsMarked"
              :key="cityIndex"
              class="city-list__side-item"
            >
              <button
                v-if="city.relationships"
                class="city-list__link"
                @click.prevent="$emit('popular-click', city)"
              >
                <b v-if="city.important">{{ city.relationships.location.cityName }}</b>
                <template v-else>
                  {{ city.relationships.location.cityName }}
                </template>
              </button>
            </div>
          </template>
        </div>
      </div>
      <div class="city-list__objects">
        <template v-for="(group, groupIndex) in list">
          <div
            v-for="(cities, letter, letterIndex) in group"
            :key="`${groupIndex} - ${letterIndex}`"
            class="city-list__group"
            :data-letter="letter"
          >
            <div
              v-for="city in cities"
              :key="city.id"
              class="city-list__group-item"
              :class="{
                '_hidden': isMainCity(city, groupIndex)
              }"
            >
              <button
                class="city-list__link"
                @click.prevent="$emit('region-click', { city, groupIndex })"
              >
                {{ getLocationName(city, groupIndex) }}
              </button>
            </div>
          </div>
        </template>
      </div>
    </template>
    <loader-block v-else text="Загружаем города" />
  </div>
</template>

<script>
import { LoaderBlock } from '@book24/ui-components';
import { MAIN_REGIONS } from '@/utils/constants/globalConst';

export default {
  components: {
    LoaderBlock,
  },
  props: {
    regionOpened: {
      type: Boolean,
      default: false,
    },
    regionName: {
      type: String,
      default: '',
    },
    popularsMarked: {
      type: Array,
      default: () => ([]),
    },
    list: {
      type: Array,
      default: () => ([]),
    },
  },
  methods: {
    isMainCity(city, groupIndex) {
      if (city.relationships?.location?.cityCod) {
        const cityCode = city.relationships.location.cityCode;
        return groupIndex === 1
          && [MAIN_REGIONS.msk.code, MAIN_REGIONS.spb.code].includes(cityCode);
      }
    },
    getLocationName(city, groupIndex) {
      if (city.relationships?.location) {
        const location = city.relationships.location;
        return groupIndex === 0
          ? location.cityName
          : location.regionName || location.cityName;
      }
    },
  },
};
</script>

<style lang="less">
@import "./styles/index.less";
</style>
