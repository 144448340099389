<template>
  <modal-vue-d
    :name="name"
    width="1140"
    class-modal="_city"
    @before-open="modalBeforeOpenHandler"
  >
    <div class="choose-city-order" :class="{ '_world-delivery': isWorldDelivery }">
      <div class="choose-city-order__heading">
        <div class="choose-city-order__title">
          Выберите город
        </div>
        <template v-if="!isWorldDelivery">
          <div class="choose-city-order__current">
            <location16-icon
              width="16"
              height="16"
              class="choose-city-order__inline-icon"
            />
            <span>Текущий город:</span>
            <nobr class="choose-city-order__current-name">
              <b>{{ city.name }}</b>
            </nobr>
          </div>
          <div class="choose-city-order__detect-block">
            <a
              class="choose-city-order__detect"
              :href="jsv"
              @click.prevent="detectClickHandler"
            >
              <location16-icon
                width="16"
                height="16"
                class="choose-city-order__inline-icon"
              />
              {{ detectCaption }}
            </a>
          </div>
        </template>
        <div v-else class="choose-city-order__line-info">
          <div class="choose-city-order__city-info">
            <div class="choose-city-order__current">
              <svg-icon
                name="16/Location_16"
                width="16"
                height="16"
                class="choose-city-order__inline-icon"
              />
              <span>Текущий город:</span>
              <nobr class="choose-city-order__current-name">
                <b>{{ city.name }}</b>
              </nobr>
            </div>
            <div class="choose-city-order__detect-block">
              <button
                class="choose-city-order__detect"
                :href="jsv"
                @click.prevent="detectClickHandler"
              >
                <location16-icon
                  width="16"
                  height="16"
                  class="choose-city-order__inline-icon"
                />
                {{ detectingCity ? 'Определяем...' : detectCaption }}
              </button>
            </div>
          </div>
          <app-link
            class="choose-city-order__world-delivery"
            href="/foreign-orders/"
            blank
          >
            <svg-icon
              class="choose-city-order__world-delivery-icon"
              name="16/Earth_16"
              width="16"
              height="16"
            />
            Международная доставка
          </app-link>
        </div>
      </div>
      <div class="choose-city-order__search-wrapper">
        <div class="choose-city-order__search">
          <search-field-city
            v-model="foundCity"
            :outer-input-props="searchInputAttributes"
            @change="searchChangeHandler"
          />
        </div>
      </div>
      <transition name="component-fade" mode="out-in">
        <notification
          v-if="notify.caption"
          class="choose-city-order__notification"
          :type="notify.type"
        >
          {{ notify.caption }}
        </notification>
      </transition>
      <div class="choose-city-order__list">
        <city-list
          v-if="showCities"
          :region-opened="regionOpened"
          :region-name="regionName"
          :populars-marked="popularCities"
          :list="list"
          @popular-click="popularClickHandler"
          @region-click="regionClickHandler"
          @back-click="backClickHandler"
        />
        <loader-block v-if="loading" text="Загружаем города" />
      </div>
    </div>
  </modal-vue-d>
</template>

<script>
import isEmpty from 'lodash.isempty';

import { LoaderBlock, Notification } from '@book24/ui-components';
import location16Icon from '@/assets/sprite/svg/16/Location_16.svg';

import sendErrorDataLayer from '@/utils/dataLayer/sendErrorDataLayer';

import ModalVueD from '@/components/ModalVueD/index';
import CityList from '@/components/CityList/index';
import SearchFieldCity from '@/components/SearchFieldCity/index';
import { MAIN_REGIONS } from '@/utils/constants/globalConst';
import useNotifications from '@/compositions/useNotifications';

const searchInputAttributes = {
  searchSmall: true,
  placeholder: 'Введите название населенного пункта',
};
export default {
  components: {
    location16Icon,
    CityList,
    LoaderBlock,
    SearchFieldCity,
    ModalVueD,
    Notification,
  },
  props: {
    isWorldDelivery: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: 'city-modal',
    },
    city: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    return {
      ...useNotifications(),
    };
  },
  data() {
    return {
      jsv: 'javascript:void(0);',
      popularCities: [],
      regionsByLetter: {},
      mainCitiesByLetter: {},
      regions: [],
      regionCities: [],
      regionName: '',
      loadingStack: [],
      detectError: false,
      foundCity: {},
      detectingCity: false,
      searchInputAttributes,
      showCities: false,
    };
  },
  computed: {
    list() {
      if (this.regionOpened) {
        return this.regionCities;
      }
      return [this.mainCitiesByLetter, this.regionsByLetter];
    },
    regionOpened() {
      return !!this.regionCities.length;
    },
    loading() {
      return this.loadingStack.length > 0;
    },
    detectCaption() {
      return this.detectError ? 'Не удалось определить автоматически' : 'Определить автоматически';
    },
    listsLoaded() {
      return this.popularCities.length && this.regionsByLetter.length;
    },
  },
  methods: {
    modalBeforeOpenHandler() {
      this.clearRegionCities();

      if (!this.listsLoaded) {
        this.updateLists();
      }
    },
    popularClickHandler(city) {
      this.setCity(city);
    },
    regionClickHandler({ city, groupIndex }) {
      if (this.regionOpened) {
        this.setCity(city);
      } else if (city.relationships?.location) {
        const isRegion = groupIndex === 1;
        const location = city.relationships.location;
        if (isRegion) {
          this.setRegionName(location.regionName);
        } else {
          this.setRegionName(location.cityName);
        }
        this.updateRegionCities(city, isRegion);
      } else {
        this.showNotifyMessage('error', 'Ошибка получения городов региона');
      }
    },
    backClickHandler() {
      this.clearRegionCities();
    },
    detectClickHandler() {
      this.detectCity();
    },
    searchChangeHandler(city) {
      if (!isEmpty(city)) {
        this.setCity(city);
      }
    },
    clearRegionCities() {
      this.regionCities = [];
    },
    setRegionName(name) {
      this.regionName = name;
    },
    setLoading(loading) {
      if (loading) {
        this.loadingStack.push(true);
      } else {
        this.loadingStack.pop();
      }
    },
    setCity(city, coordinates) {
      if (city.relationships?.location?.cityName) {
        const location = city.relationships.location;
        if (location.cityName.includes(',')) {
          location.cityName = city.name.split(',')[0];
        }
      }

      this.$emit('selected', { city, coordinates });
      this.hideModal();
    },
    hideModal() {
      this.clearLoading();
      this.$nextTick(() => this.$modal.hide('city-modal'));
    },
    clearLoading() {
      this.loadingStack = [];
    },
    async detectCity() {
      this.detectingCity = true;

      try {
        const { city, coordinates } = await this.$httpClient
          .location.geoDetect(this.$config.ymapApiKey);
        this.detectError = false;
        this.setCity(city, coordinates);
      } catch (e) {
        this.detectError = true;
        sendErrorDataLayer('Ошибка определения города', e);
      } finally {
        this.detectingCity = false;
      }
    },
    isMainCity(city, withSevastopol = false) {
      const cityCode = city.relationships?.location?.cityCode;
      const mainCities = [
        MAIN_REGIONS.msk.code,
        MAIN_REGIONS.spb.code,
      ];
      if (withSevastopol) mainCities.push(MAIN_REGIONS.svp.code);
      return mainCities.includes(cityCode);
    },
    async getLocations() {
      const { list: formattedLocations } = await this.$httpClient.location.getPopularLocations();
      return formattedLocations;
    },
    groupLocationsByType(locations) {
      this.popularCities = [];
      this.regions = [];
      this.mainCitiesByLetter = {};
      this.regionsByLetter = {};
      locations.forEach((location) => {
        const level = location.level;
        if (level) {
          if (level === 'city') {
            if (this.isMainCity(location, true)) {
              location.important = true;
              this.mainCitiesByLetter[location.firstLetter] = [location];
            }
            this.popularCities.push(location);
          } else if (!this.isMainCity(location)) {
            this.regions.push(location);
          }
        }
      });
    },
    async buildRegionsList() {
      await this.regions.forEach((region) => {
        if (!this.regionsByLetter[region.firstLetter]) {
          this.regionsByLetter[region.firstLetter] = [];
        }
        this.regionsByLetter[region.firstLetter].push(region);
      });
    },
    async updateLists() {
      this.setLoading(true);
      this.showCities = false;
      try {
        const locations = await this.getLocations();
        await this.groupLocationsByType(locations);
        await this.sortLocationsByLetter();
        await this.buildRegionsList();
        this.showCities = true;
      } catch (e) {
        sendErrorDataLayer('Ошибка получения популярных городов', e);
      } finally {
        this.setLoading(false);
      }
    },
    async updateRegionCities(city, isRegion) {
      this.setLoading(true);

      try {
        if (city.relationships?.location) {
          const loc = city.relationships.location;
          let locationCode = null;
          if (isRegion && loc.regionCode) {
            locationCode = loc.regionCode;
          } else {
            locationCode = loc.cityCode;
          }
          const locations = await this.$httpClient.location.getCitiesOfRegion(locationCode);
          if (this.isMainCity(city, true)) {
            locations.push({
              id: city.id,
              attributes: {
                type: 'city',
                ...loc,
              },
            });
          }
          const cities = await this.groupRegionCities(locations);
          this.regionCities = [cities];
        } else {
          this.showNotifyMessage('error', 'Ошибка получения городов региона');
        }
      } catch (e) {
        this.showNotifyMessage('error', 'Ошибка получения городов региона');
        sendErrorDataLayer('Ошибка получения городов региона', e);
      } finally {
        this.setLoading(false);
      }
    },
    showNotifyMessage(type, caption) {
      this.showNotify(type, caption);
      setTimeout(() => {
        this.hideNotify();
      }, 3000);
    },
    groupRegionCities(locations) {
      const cities = {};
      locations.forEach((location) => {
        if (location.attributes?.type === 'city') {
          const nameFirstLetter = location.attributes.cityName[0];
          if (!cities[nameFirstLetter]) {
            cities[nameFirstLetter] = [];
          }
          if (!location.relationships) {
            location.relationships = {
              location: {},
            };
          }
          location.relationships.location.cityName = location.attributes.cityName;
          location.relationships.location.cityCode = location.attributes.cityCode;
          cities[nameFirstLetter].push(location);
        }
      });

      Object.keys(cities).forEach((letter) => {
        cities[letter] = cities[letter].sort((a, b) => {
          const valueA = a.relationships.location.cityName;
          const valueB = b.relationships.location.cityName;

          if (valueA < valueB) {
            return -1;
          } if (valueA > valueB) {
            return 1;
          }
          return 0;
        });
      });
      return cities;
    },
    async sortLocationsByLetter() {
      return this.regions.sort((a, b) => {
        const valueA = a.firstLetter;
        const valueB = b.firstLetter;

        if (valueA < valueB) {
          return -1;
        } if (valueA > valueB) {
          return 1;
        }
        return 0;
      });
    },
  },
};
</script>

<style lang="less">
@import "./styles/index.less";
@import "./styles/_world-delivery.less";
</style>
