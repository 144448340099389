<template>
  <search-field-d
    tooltip-message="Выберите город из списка"
    :input="inputProps"
    :list="searchList"
    :outer-query="query"
    :error="error"
    :valid="valid"
    :show-tooltip="showMessage"
    :results-visible-outer="showMessage"
    @searching="searchingHandler"
    @select="selectHandler"
    @click-outside="clickOutsideHandler"
  />
</template>

<script>
import debounce from 'lodash.debounce';
import isEmpty from 'lodash.isempty';

import sendErrorDataLayer from '@/utils/dataLayer/sendErrorDataLayer';
import SearchFieldD from '@/components/SearchFieldD/index';

const limit = 30;
const minQueryLength = 2;

export default {
  components: { SearchFieldD },
  model: {
    prop: 'outerSelectedCity',
    event: 'change',
  },
  props: {
    outerSelectedCity: {
      type: Object,
      default: () => ({}),
    },
    error: {
      type: String,
      default: '',
    },
    valid: {
      type: Boolean,
      default: false,
    },
    outerInputProps: {
      type: Object,
      default: () => ({}),
    },
    intrusively: {
      type: Boolean,
      default: false,
    },
    currentView: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      query: this.outerSelectedCity.name || '',
      citiesList: [],
      loadingStack: [],
      selectedCity: this.outerSelectedCity,
    };
  },
  computed: {
    searchList() {
      return this.citiesList.map(city => ({
        caption: city.name,
        city,
      })).slice(0, limit);
    },
    inputProps() {
      const defaultProps = {
        placeholder: 'Например, Москва',
        loading: !!this.loadingStack.length,
      };

      return isEmpty(this.outerInputProps) ? defaultProps : this.outerInputProps;
    },
    showMessage() {
      return this.selectedCity.name !== this.query && this.intrusively;
    },
  },
  watch: {
    outerSelectedCity(city) {
      this.query = city.name;
      this.selectedCity = city;
    },
  },
  methods: {
    // обработчик поиска
    searchingHandler(query) {
      if (query.length < minQueryLength) return;

      const { selectedCity } = this;

      this.query = query;
      this.updateCities();

      if (selectedCity.location && selectedCity.name !== query) {
        this.selectedCity = {};
      }
    },
    // обработчик выбора
    selectHandler(item = {}) {
      const { city } = item;
      this.updateCity(city || {});
    },
    // обработчик потери фокуса поля
    clickOutsideHandler() {
      if (!this.selectedCity.location && !this.intrusively) {
        this.clear();
      }
    },
    // очистить поле
    clear() {
      this.updateCity({});
    },
    updateCity(city) {
      this.selectedCity = city;
      this.$emit('change', city);
    },
    // управление прелоадером
    setLoading(state) {
      const { loadingStack } = this;

      if (state) {
        loadingStack.push(true);
      } else {
        loadingStack.pop();
      }
    },
    // подгрузить список городов
    updateCities: debounce(async function updateCitiesFnc() {
      this.setLoading(true);

      try {
        this.citiesList = await this.$httpClient.location.getCities(this.query);
      } catch (e) {
        sendErrorDataLayer('Ошибка запроса списков городов', e);
      } finally {
        this.setLoading(false);
      }
    }, 250),
  },
};
</script>
