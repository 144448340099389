import isEmpty from 'lodash.isempty';
import get from 'lodash.get';

import pushToDataLayer from '@/utils/dataLayer/pushToDataLayer';

// формирование ошибки
const errorMsg = (e) => {
  let errorVar = e;
  if (!isEmpty(get(e, 'data', {}))) {
    errorVar = e.data.MESSAGE;
  }
  return errorVar;
};
// запись ошибки в dataLayer и отправка события об ошибке
export default function sendErrorDataLayer(msg, e) {
  pushToDataLayer({ checkoutErrorMessage: `${msg}: ${errorMsg(e)}` });
  pushToDataLayer({ event: 'checkout_error' });
}
