<template>
  <div class="search-results-d">
    <div v-if="message" class="search-results-d__message">
      {{ message }}
    </div>
    <template v-else>
      <div
        v-for="(item, index) in list"
        :key="index"
        class="search-results-d__item"
      >
        <a
          :href="jsv"
          class="search-results-d__link"
          :class="getLinkClasses(item)"
          @click.prevent="$emit('select', item)"
        >
          <span v-sanitize="getSearchCaption(item.caption)" class="search-results-d__caption"></span>
          <span v-if="item.count" class="search-results-d__count">
            {{ item.count }}
          </span>
        </a>
      </div>
    </template>
  </div>
</template>

<script>
const wrapQuery = query => `<span class="search-results-d__query">${query}</span>`;
const escape = query => query.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

export default {
  props: {
    list: {
      type: Array,
      default: () => ([]),
    },
    query: {
      type: String,
      default: '',
    },
    selected: {
      type: Object,
      default: () => ({}),
    },
    message: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      jsv: 'javascript:void(0);',
    };
  },
  methods: {
    // получить подготовленный текст строки
    getSearchCaption(caption) {
      const query = this.query;

      if (query) {
        const re = new RegExp(escape(query), 'ig');
        return caption.replace(re, wrapQuery);
      }

      return caption;
    },
    isSelected(item) {
      return item === this.selected;
    },
    getLinkClasses(item) {
      return {
        _selected: this.isSelected(item),
        _count: item.count,
      };
    },
  },
};
</script>

<style lang="less">
@import "./styles/index";
</style>
