<template>
  <div v-click-outside="clickOutsideHandler" class="search-field-d">
    <div
      v-if="isMounted"
      class="search-field-d__control"
    >
      <input-control
        ref="searchInput"
        v-model="query"
        type="text"
        v-bind="input"
        @input="fieldInputHandler"
        @focus="focused = true"
      >
        <template #prepend>
          <svg-icon
            name="24/Search_24"
            class="b24-input-control__control-icon"
            :width="24"
            :height="24"
          />
        </template>
      </input-control>
    </div>
    <div v-if="resultsVisible" class="search-field-d__results _visible">
      <search-results-d
        :list="listData"
        :query="query"
        :message="message"
        @select="resultsSelectHandler"
      />
    </div>
  </div>
</template>

<script>
import { InputControl } from '@book24/ui-components';
import SearchResultsD from '@/components/SearchResultsD/index';

export default {
  components: {
    SearchResultsD,
    InputControl,
  },
  props: {
    input: {
      type: Object,
      default: () => ({}),
    },
    list: {
      type: Array,
      default: () => ([]),
    },
    outerQuery: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: '',
    },
    valid: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: '',
    },
    tooltipMessage: {
      type: String,
      default: '',
    },
    showTooltip: {
      type: Boolean,
      default: false,
    },
    resultsVisibleOuter: {
      type: Boolean,
      default: false,
    },
    focusedAfterSelect: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      query: this.outerQuery,
      isMounted: false,
      focused: false,
      hideResults: true,
      listData: [],
    };
  },
  computed: {
    tooltip() {
      return {
        content: this.$refs.tooltip,
        show: true,
        classes: 'tooltip-custom _input',
      };
    },
    resultsVisible() {
      const { focused } = this;
      const listLength = this.listData.length;

      if (!this.focusedAfterSelect) {
        return (focused && (listLength || this.message))
          || (listLength && this.resultsVisibleOuter);
      }
      return this.hideResults && (listLength || this.message.length);
    },
    clearVisible() {
      return this.query && !this.input.loading;
    },
  },
  watch: {
    list(value) {
      this.listData = value;
    },
    outerQuery(value) {
      this.query = value;
    },
    focused(value) {
      this.$emit('focus', value);
    },
  },
  mounted() {
    this.isMounted = true;
  },
  methods: {
    // обработка выбора в списке
    resultsSelectHandler(item) {
      if (this.focusedAfterSelect) {
        this.hideResults = false;
      }
      this.focused = false;
      this.query = item.caption;
      this.$emit('select', item);
    },
    // клик вне компонента
    clickOutsideHandler() {
      if (this.focusedAfterSelect) {
        this.hideResults = false;
      }
      this.focused = false;
      this.$emit('click-outside');
    },
    // ввод в поле поиска
    fieldInputHandler(query) {
      if (this.focusedAfterSelect) {
        this.hideResults = true;
      }
      this.$emit('searching', query);
    },
    // очистить поле
    clear() {
      this.query = '';
      this.listData = [];
      this.$refs.searchInput.focus();
      this.$emit('select');
    },
    // устанавливаем фокус в инпут
    focusInput() {
      this.$refs.searchInput.focus();
      this.focused = true;
    },
    changeHideResults() {
      this.hideResults = false;
    },
  },
};
</script>

<style lang="less">
@import "./styles/index";

</style>
